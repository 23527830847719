<!--
 * @Author: wyq
 * @Date: 2021-08-06 14:37:54
 * @LastEditTime: 2021-10-12 15:12:19
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\components\dialogs\editReasonMsgBox.vue
-->
<template>
  <msg-box-template
    class="edit-reason-dialog"
    :visible.sync="dialogVisible"
    title="编辑错因标签"
    :imgUrl="require('@/assets/images/dialog/edit.png')"
  >
    <ul class="tag-list">
      <li
        v-for="item in $store.state.tagList"
        :key="item.value"
        :class="{'active':currentErrorReasonId&&currentErrorReasonId.find(i=>i==item.value)}"
        @click="changeTag(item.value)"
      >{{item.label}}</li>
    </ul>
    <template slot="btnGroup">
      <v-btn
        color="primary"
        depressed
        block
        x-large
        class="rounded-sm"
        @click="submit"
        :loading="submitLoadding"
      >确定</v-btn>
    </template>
  </msg-box-template>
</template>
<script>
import msgBoxTemplate from './msgBoxTemplate'
import mixin from '@/utils/dialogMixin'
export default {
  components: {
    msgBoxTemplate,
  },
  mixins: [mixin],
  props: {
    errorReasonId: Array,
  },
  data() {
    return {
      currentErrorReasonId: [],
    }
  },
  watch: {
    errorReasonId: {
      handler(val) {
        if (val != this.currentErrorReasonId) {
          if (val) {
            this.currentErrorReasonId = val
          } else {
            this.currentErrorReasonId = []
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    changeTag(val) {
      let index = this.currentErrorReasonId.findIndex((i) => i == val)
      if (index >= 0) {
        this.currentErrorReasonId.splice(index, 1)
      } else {
        this.currentErrorReasonId.push(val)
      }
    },
    submit() {
      this.dialogVisible = false
      this.$emit('submit', this.currentErrorReasonId)
    },
  },
}
</script>
<style lang="scss" scoped>
.edit-reason-dialog {
  .tag-list {
    padding: 0;
    li {
      display: inline-block;
      height: 42px;
      padding: 0 20px;
      margin-right: 20px;
      margin-bottom: 16px;
      line-height: 42px;
      font-size: 16px;
      background-color: #f0f0f0;
      border-radius: 22px;
      color: #333333;
      border: solid 1px #f0f0f0;
      &.active {
        background: rgba(0, 74, 255, 0.1);
        border: 1px solid #0148ff;
        color: #0148ff;
      }
    }
  }
}
</style>