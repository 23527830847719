<!--
 * @Author: wyq
 * @Date: 2021-10-02 17:25:10
 * @LastEditTime: 2021-10-28 10:40:32
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\components\testing\questionPreview.vue
-->
<template>
  <div class="question-container">
    <div class="question-container-left">
      <template v-if="questionInfo.costSecond">
        <div class="clock">
          <v-icon>icon-clock</v-icon>
          <span>{{ questionInfo.costSecond | formatSeconds }}</span>
        </div>
        <v-divider light></v-divider>
      </template>
      <question-stem :questionInfo="questionInfo"></question-stem>
    </div>
    <div class="question-container-right">
      <div class="content" :class="{ full: !drawer }">
        <question-options :questionInfo="questionInfo" disabled :hideAnswer="drawer && !isShow"></question-options>
        <template v-if="!drawer || (drawer && isShow)">
          <!-- <div v-show="questionInfo.myAnswer" class="result-item">
            <div class="result-item-title">我的答案</div>
            <div class="result-item-content-inline">
              <span v-if="questionInfo.responseStatus == 3" class="color-999">未作答</span>
              <span v-else class="color-999">
                <span v-if="questionInfo.type == 1" class="color-success">
                  选项
                  {{ String.fromCharCode(65 + questionInfo.successOption) }}
                </span>
                <span v-else-if="questionInfo.type == 4">
                  选项
                  <span v-for="i in questionInfo.myAnswer" :key="i">{{ String.fromCharCode(65 + parseInt(i)) }}</span>
                </span></span>
              <v-btn v-if="questionInfo.isError && questionInfo.errorQuestionStatus == 1" class="ma-2" outlined
                color="error" small>已加入错题本</v-btn>
              <template v-else>
                <span>&nbsp;&nbsp;&nbsp;没掌握？</span>
                <v-btn class="ma-2" outlined color="primary" small @click="changeReason">加入错题本</v-btn>
              </template>
            </div>
          </div> -->

          <div v-show="questionInfo.type" class="result-item">
            <div class="result-item-title">正确答案</div>
            <div class="result-item-content-inline">
              <span v-if="questionInfo.type == 1">
                选项 {{ String.fromCharCode(65 + Number(questionInfo.answer)) }}
              </span>
              <span v-else-if="questionInfo.type == 4">
                选项
                <span v-for="i in questionInfo.arrAnswer" :key="i">{{ String.fromCharCode(65 + parseInt(i)) }}</span>
              </span>
            </div>
          </div>

          <div v-show="questionInfo.type" class="result-item">
            <div class="result-item-title">错因标记</div>
            <div>
              <v-chip v-for="errorReason in questionInfo.errorReason" :key="errorReason.value" class="ma-2">
                {{ errorReason.label }}</v-chip>
              <!--  -->
              <span v-if="questionInfo.errorReason && questionInfo.errorReason.length == 0"
                class="color-999 ma-2">您还未添加错因</span>
              <v-btn v-if="questionInfo.isError && questionInfo.errorQuestionStatus == 1" fab dark small
                color="primary">
                <v-icon dark @click="changeReason">mdi-plus</v-icon>
              </v-btn>
            </div>
          </div>

          <div v-show="questionInfo.type" class="result-item">
            <div class="result-item-title">题目解析</div>
            <question-analyze :questionInfo="questionInfo"></question-analyze>
          </div>
        </template>
      </div>
      <div v-if="drawer" class="action-bar">
        <v-btn v-if="isShow" large color="primary" @click="isShow = false">
          <v-icon>icon-arrow-up</v-icon>收起解析
        </v-btn>
        <v-btn v-else large color="primary" @click="isShow = true">
          <v-icon>icon-arrow-down</v-icon>查看解析
        </v-btn>
      </div>
    </div>
    <edit-reason-msg-box :visible.sync="reasonVisible" @submit="reasonSubmit"
      :errorReasonId="questionInfo.errorReasonId"></edit-reason-msg-box>
  </div>
</template>
<script>
import questionStem from './questionStem.vue'
import questionOptions from './questionOptions'
import questionAnalyze from './questionAnalyze.vue'
import editReasonMsgBox from '../dialogs/editReasonMsgBox'
export default {
  components: {
    questionStem,
    questionOptions,
    questionAnalyze,
    editReasonMsgBox,
  },
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
    questionInfo: Object,
  },
  data() {
    return {
      reasonVisible: false,
      isShow: false,
    }
  },
  methods: {
    changeReason() {
      this.reasonVisible = true
    },
    jumpOverQuestion() {
      this.$emit('jumpOverQuestion')
    },
    submitAnswer() {
      this.$emit('submitAnswer')
    },
    reasonSubmit(val) {
      let params = {
        questionId: this.questionInfo.questionId,
        errorReasonId: val,
      }
      this.$service.updateQuestionErrorInfo(params).then(() => {
        this.$message.success('修改成功')
        this.$emit('reasonChange')
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../../sass/question.scss';

.question-container {
  .content {
    overflow: auto;

    &.full {
      height: 100%;
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .result-item {
      margin-top: 32px;

      &-title {
        display: inline-block;
        font-size: 20px;
        font-family: PingFangSC-Medium;

        &::before {
          display: inline-block;
          content: '';
          width: 6px;
          height: 20px;
          margin-right: 16px;
          background: #0148ff;
          border-radius: 6px;
          vertical-align: text-top;
        }
      }

      &-content-inline {
        display: inline-block;
        margin-left: 24px;
        font-size: 20px;
      }

      .v-chip {
        background-color: #eee;
        color: #6a6a6a;
      }
    }
  }

  .color-success {
    color: #52c41a;
  }

  .color-999 {
    color: #999;
  }
}
</style>