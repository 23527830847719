<!--
 * @Author: wyq
 * @Date: 2021-10-03 12:57:43
 * @LastEditTime: 2022-04-15 10:41:19
 * @LastEditors: yy
 * @Description: 
 * @FilePath: \cat_student\src\components\testing\questionAnalyze.vue
-->
<template>
  <div ref="katex" v-show="show" class="question-analyze" :key="questionInfo.questionId">
    <div
      v-if="questionInfo.answerAnalysis||(questionInfo.answerAnalysisImages&&questionInfo.answerAnalysisImages.length)"
    >
      <span>[分析]</span>
      <span>{{questionInfo.answerAnalysis}}</span>
    </div>
    <div v-for="(item,index) in questionInfo.answerAnalysisImages" :key="item.url" class="image">
      <img :src="item.url" @click="previewImg(questionInfo.answerAnalysisImages,index)" />
      <p>{{item.label}}</p>
    </div>
    <div>
      <span>[解答]</span>
      <span>{{questionInfo.answerProcess}}</span>
    </div>
    <div v-for="(item,index) in questionInfo.answerProcessImages" :key="item.url" class="image">
      <img :src="item.url" @click="previewImg(questionInfo.answerProcessImages,index)" />
      <p>{{item.label}}</p>
    </div>
     <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-img :src="previewImage"> </v-img>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    questionInfo: Object,
  },
  watch: {
    'questionInfo.questionId': {
      handler() {
        this.show = false
        setTimeout(() => {
          this.$formula(this.$refs['katex'])
          setTimeout(() => {
            this.show = true
          }, 100)
        }, 100)
      },
      immediate: true,
    },
  },
  data() {
    return {
      show: false,
       dialog:false,
      previewImage:null
    }
  },
  methods: {
    previewImg(img, index) {
      console.log(img[index].url);
      this.previewImage = img[index].url;
      this.dialog = true
    },
  },
}
</script>
<style lang="scss" scoped>
.question-analyze {
  padding: 10px 0;
  white-space: pre-wrap;
  .image {
    display: inline-block;
    width: 150px;
    margin: 10px 0;
    img {
      width: 100%;
      height: auto;
    }
    p {
      margin: 0;
      text-align: center;
    }
    & + .image {
      margin-left: 20px;
    }
  }
}
</style>